<template>
	<div id="app-confirmation">
		<app-stepper :step-number="7"></app-stepper>

		<div class="row flex-column align-items-center mainBox">
			<div class="col-12 mb-4 d-flex justify-content-center">
				<div class="dataIconBC"></div>
			</div>
			<div
				v-if="$store.state.onboarding.productId === 'CTATRANS'"
				class="col-11"
			>
				<h2 class="col-11 col-xl-8 mb-5 text-center text-format-1 ml-auto mr-auto">
					¡Muchas gracias, {{ $store.state.onboarding.clientName }}! Queda muy poco para
					finalizar el proceso de contratación.
				</h2>
				<div class="flex-column justify-content-center align-items-center row">
					<p class="m-0 text-format-2">
						En breve recibirás en tu correo electrónico los documentos asociados:
					</p>
					<ul class="mt-3 text-format-2">
						<li>Información precontractual del producto.</li>
						<li v-if="$store.state.onboarding.productId === 'CTATRANS'">
							Documento de Información de Comisiones
						</li>
						<li>Documento de aceptación de contrato y condiciones particulares.</li>
					</ul>
				</div>
				<p class="text-center m-0 mt-md-5 mt-3 text-format-2">
					¡Por favor! No olvides firmarlos para poder disfrutar de todos sus beneficios.
				</p>
				<p class="text-center m-0 mt-2 mb-5 mb-sm-1 text-format-3">¡GRACIAS!</p>
			</div>
			<div
				v-else
				class="col-11"
			>
				<h2 class="m-0 mb-5 text-center text-format-1">
					¡Muchas gracias, {{ $store.state.onboarding.clientName }}!
				</h2>
				<p class="m-0 text-format-2 text-center">
					En breve contactaremos contigo para finalizar el proceso.
				</p>
			</div>
			<p class="m-0 mt-md-1 mt-4 text-format-2">
				Mientras tanto, te invitamos a que descargues nuestra aplicación disponible en:
			</p>
			<div class="badges d-flex justify-content-center mt-4">
				<a
					class="badgeApple"
					href="https://apps.apple.com/us/app/banco-caminos/id1487466944"
					target="_blank"
				></a>
				<a
					class="badgeGoogl"
					href="https://play.google.com/store/apps/details?id=es.grupocaminos.bancocaminos&hl=es_419"
					target="_blank"
				></a>
			</div>
		</div>
	</div>
</template>

<script>
import AppStepper from '@/components/app-stepper';

export default {
	name: 'app-confirmation',

	components: {
		AppStepper,
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 30px 0;
}
ul {
	margin: 0 60px;
}
.dataIconBC {
	height: 62px;
	width: 62px;
	background-image: url(~@project/assets/img/end.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.badges .badgeApple {
	margin-right: 10px;
	height: 50px;
	width: 170px;
	background-image: url(~@/assets/images/Apple.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.badges .badgeGoogl {
	margin-left: 10px;
	height: 50px;
	width: 170px;
	background-image: url(~@/assets/images/Google.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
@media screen and (max-device-width: 480px) {
	p {
		text-align: center;
		display: inline-block;
		padding: 0 10px;
	}
}
</style>
